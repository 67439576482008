<template>
  <b-avatar
    v-b-tooltip.hover
    v-bind="$attrs"
    :src="userAvatar"
    :text="text"
    :alt="name"
    :title="name"
    class="user-avatar"
  >
    <template v-if="$slots.badge" #badge>
      <slot name="badge" />
    </template>
  </b-avatar>
</template>

<script>
import { BAvatar, VBTooltip } from 'bootstrap-vue';
import { getImageResource } from '@/@core/utils/image-utils';

export default {
  name: 'UserAvatar',
  components: { BAvatar },
  directives: { BTooltip: VBTooltip },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userAvatar() {
      return getImageResource(this.user?.avatarURL || this.user?.avatarUrl || this.user?.avatar_url);
    },
    text() {
      //No text if image
      if (this.userAvatar) {
        return '';
      }
      //print initials
      if (this.user?.name && this.user?.surname) {
        return `${this.user?.name[0]}${this.user?.surname[0]}`.toUpperCase();
      }
      return '';
    },
    name() {
      return `${this.user?.name} ${this.user?.surname}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.user-avatar::v-deep {
  img {
    object-fit: cover;
    object-position: top;
  }
}
</style>
